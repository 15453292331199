import { h } from 'preact';
import { CloseIcon } from './closeIcon';
import { ExternalLinkIcon } from './externalLinkIcon';
import { FocusTrap } from './focus-trap-preact';

export const Modal = ({ email = "email@domain.com", site = "Skills", personal = "A Cloud Guru", business = "Pluralsight", onClose}) => {
  return (
    <div class="modal">
        <div class="modalBackdrop" />
        <FocusTrap
          focusTrapOptions={{
            onDeactivate: onClose
          }}>
        <div role="dialog" aria-labelledby="dialogTitle" aria-describedby="dialogDesc" class="modalContainer">
          <div class="modalContent">
            <div class="modalContent--Close" tabIndex="0" aria-label="Close modal dialog" onClick={onClose}>
              <CloseIcon />
            </div>
          <div class="modalContent--Header">
            <h1 id="dialogTitle">Update your account to go to {site}</h1>
            <p id="dialogDesc">Your email address <strong>{email}</strong> is registered to both your <strong>personal {personal} account and your {business} employer/business-provided account.</strong></p>
            <p>In order to move between the two platforms and experience future integration awesomeness you have a couple of options:</p>
            </div>
            <div class="modalContent--Row">
            <div class="modalContent--Column">
              <div>
                <div class="modalContent--Option modalContent--Option__one">Recommended</div>
                <h2>Update your {personal} email address</h2>
                <p>Yep, just link your personal {personal} account ({email}) to your personal email address to keep your accounts separate.</p>
                <p>If you’re not sure how to do that, we’ve got you covered in the guide below.</p>
              </div>
              <a href="https://help.acloud.guru/hc/en-us/articles/4758555404047-Pluralsight-ACG-Data-Sharing-FAQ" target="_blank" rel="noopener noreferrer" class="modalContent--Button__primary">
                See update guide
                <ExternalLinkIcon />
              </a>
            </div>
            <div class="modalContent--Column">
              <div>
                <div class="modalContent--Option modalContent--Option__two">Option 2</div>
                <h2>Merge your two accounts’ data</h2>
                <p>You can merge your existing personal account data with your business account.</p>
                <p>This option requires you to opt in before your accounts can be merged.</p>
              </div>
              <a href="https://acloudguru.com/content/nimbus" target="_blank" rel="noopener noreferrer" class="modalContent--Button__secondary">
                Go to opt in
                <ExternalLinkIcon />
              </a>
            </div>
            </div>
          </div>
        </div>
        </FocusTrap>
    </div>
  )
}
